<template>
    <div class="section">
        <div class="section has-text-centered">
            <svg viewBox="0 0 100 100" class="animate">
                <filter id="dropshadow" height="100%">
                    <feGaussianBlur
                        in="SourceAlpha"
                        stdDeviation="3"
                        result="blur"
                    />
                    <feFlood
                        flood-color="rgba(76, 175, 80, 1)"
                        flood-opacity="0.5"
                        result="color"
                    />
                    <feComposite
                        in="color"
                        in2="blur"
                        operator="in"
                        result="blur"
                    />
                    <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>

                <circle
                    cx="50"
                    cy="50"
                    r="46.5"
                    fill="none"
                    stroke="rgba(76, 175, 80, 0.5)"
                    stroke-width="5"
                />

                <path
                    d="M67,93 A46.5,46.5 0,1,0 7,32 L43,67 L88,19"
                    fill="none"
                    stroke="rgba(76, 175, 80, 1)"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-dasharray="80 1000"
                    stroke-dashoffset="-220"
                    style="filter:url(#dropshadow)"
                />
            </svg>
        </div>
        <div class="section content has-text-centered">
            <h1 class="title">
                {{ $t('booking.confirmation.title') }}
            </h1>
            <!-- eslint-disable vue/no-v-html -->
            <p
                v-html="
                    $t('booking.confirmation.message', {
                        address: booking.fullAddress
                    })
                "
            ></p>
            <!-- eslint-enable -->
            <p>{{ $t('booking.confirmation.summary') }}</p>
            <div class="columns is-centered">
                <div class="column is-half is-two-thirds-mobile">
                    <div class="box has-text-left">
                        <p v-if="isSeller">
                            <span class="has-text-weight-semibold">
                                {{ $t('booking.confirmation.dateAndTime') }}
                            </span>
                            <span class="is-italic">
                                {{
                                    booking.confirmedDate
                                        | formatDate('yyyy-MM-dd HH:mm')
                                }}
                            </span>
                        </p>

                        <p v-else>
                            <span class="has-text-weight-semibold">
                                {{ $t('booking.confirmation.dateAndTime') }}
                            </span>
                            <span class="is-italic">
                                {{
                                    booking.reservationFrom
                                        | formatDate('yyyy-MM-dd HH:mm')
                                }}
                                -
                                {{
                                    booking.reservationTo
                                        | formatDate('yyyy-MM-dd HH:mm')
                                }}
                            </span>
                        </p>

                        <p>
                            <span class="has-text-weight-semibold">
                                {{ $t('booking.confirmation.product') }}
                            </span>
                            <span class="is-italic">
                                {{ booking.product.name }}
                                {{ $t('booking.confirmation.withSeller') }}
                                <span class="has-text-weight-semibold">
                                    {{ booking.owner.fullName }}
                                </span>
                            </span>
                        </p>

                        <p>
                            <span class="has-text-weight-semibold">
                                {{ $t('booking.confirmation.customerDetails') }}
                            </span>
                            <span class="is-italic">
                                {{ booking.name }}
                            </span>
                            <span v-if="booking.phone">
                                , {{ booking.phone }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            <p>
                {{ $t('booking.confirmation.emailConfirmation') }}
            </p>
            <div class="buttons is-centered">
                <router-link
                    :to="{ name: 'Overview' }"
                    class="button is-light is-rounded is-info"
                >
                    {{ $t('booking.confirmation.buttons.no') }}
                </router-link>
                <a
                    class="button is-rounded is-success"
                    @click="emailConfirmation"
                >
                    {{ $t('booking.confirmation.buttons.yes') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Confirmation',
    props: { booking: Object },
    computed: {
        isSeller() {
            return this.$store.getters['security/hasRole']('ROLE_SELLER')
        }
    },
    methods: {
        emailConfirmation() {
            alert('Not implemented yet')
            this.$router.push({ path: '/overview' }).catch(() => {})
        }
    }
}
</script>

<style scoped>
svg {
    width: 100px;
    height: 100px;

    position: absolute;
    top: 50px;
    left: 50%;
    margin-left: -50px;
}

svg.animate path {
    animation: dash 0.75s linear both;
    animation-delay: 0.5s;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 210;
    }
    75% {
        stroke-dashoffset: -220;
    }
    100% {
        stroke-dashoffset: -205;
    }
}
</style>
