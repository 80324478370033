<template>
    <section class="section">
        <div class="columns is-centered">
            <div class="column is-four-fifths">
                <h1 v-if="!bookingDetails" class="title">
                    {{ $t('booking.header') }}
                </h1>
                <transition name="bounce" mode="out-in">
                    <CreateBooking
                        v-if="!bookingDetails"
                        @booking-complete="bookingDetails = $event"
                    />
                    <BookingConfirmation v-else :booking="bookingDetails" />
                </transition>
            </div>
        </div>
    </section>
</template>

<script>
import CreateBooking from '../../components/booking/BookingMobile'
import BookingConfirmation from '../../components/booking/Confirmation'

export default {
    name: 'NewBooking',
    components: { CreateBooking, BookingConfirmation },
    data() {
        return {
            bookingComplete: false,
            bookingDetails: null
        }
    }
}
</script>

<style scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
</style>
